<template>
  <div v-if="employee">

    <validation-observer #default="{handleSubmit, errors}">
      <b-form @submit.prevent="handleSubmit(submitEmployee)">
        <form-employee
            :employee="employee"
            :formErrors="errors"
        />

        <save-button @click="handleSubmit(submitEmployee)"/>

        <!--        <button ref="submitEmployeeFormRef"-->
        <!--                class="d-none"></button>-->
      </b-form>
    </validation-observer>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { useEmployees } from './useEmployees'
import { mixinEmployees } from './mixinEmployees'
import { ValidationObserver } from 'vee-validate'

import FormEmployee from '@/components/form/employee/Employee'
import store from '@/store'
import router from '@/router'
import SaveButton from '@/components/button/Save'

export default {
  components: {
    ValidationObserver,
    FormEmployee,
    SaveButton
  },
  mixins: [mixinEmployees],
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const submitEmployeeFormRef = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const employee = computed(() => {
      let employee = store.getters['user/getUser'](router.history.current.params.employeeId)

      if (
          typeof employee != 'undefined' &&
          'addresses' in employee
      ) {
        return employee
      }

    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    const { submitValidatedEmployee } = useEmployees()

    const submitForm = () => {
      submitEmployeeFormRef.value.click()
    }

    const submitEmployee = () => {
      submitValidatedEmployee(employee.value)
          .then(response => {
            router.push({ name: 'Employees' })
          })

    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    if (employee.isEnabled != true) {
      // router.push({name: 'Employees'})
    }

    return {
      // Components

      // Data
      submitEmployeeFormRef,

      // Computed
      employee,

      // Methods
      submitForm,
      submitEmployee
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>