<template>

  <b-card>

    <b-tabs
        v-if="employee"
        pills
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <icon icon="user"
                class="mr-0 mr-sm-50"/>
          <span
              :class="{'text-danger font-weight-bolder': isAccountTabError}"
              class="d-none d-sm-inline">{{ $t('Account') }}</span>
        </template>

        <account
            :employee="employee"
            class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Contracts -->
      <b-tab>
        <template #title>
          <icon icon="file-contract"
                class="mr-0 mr-sm-50"/>
          <span :class="{'text-danger font-weight-bolder': isContractsTabError}"
                class="d-none d-sm-inline">{{ capitalize($tc('contract', 2)) }}</span>
        </template>

        <employee-contracts
            :employeeContracts.sync="employee.contracts"
        />
      </b-tab>

      <!-- Tab: Documents -->
      <b-tab>
        <template #title>
          <icon icon="folder"
                class="mr-0 mr-sm-50"/>
          <span class="d-none d-sm-inline">{{ capitalize($tc('document', 2)) }}</span>
        </template>

        <employee-documents
            :employee.sync="employee"
        />
      </b-tab>

    </b-tabs>
  </b-card>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '@/utils/filter'

import Account from './components/_account'
import EmployeeContracts from '../employeeContracts/EmployeeContracts'
import EmployeeDocuments from './components/_documents'

export default {
  components: {
    Account,
    EmployeeContracts,
    EmployeeDocuments
  },
  props: {
    employee: {
      type: Object,
      default: () => {}
    },
    formErrors: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isAccountTabError = ref(false)
    const isContractsTabError = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------

    return {
      // Components
      capitalize,

      // Data
      isAccountTabError,
      isContractsTabError,

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    formErrors: function (val) {
      this.isProductsTabError = false
      this.isTermsTabError = false
      this.isOrderTabError = false

      for (const [field, errorArrray] of Object.entries(val)) {
        // console.log(field, errorArrray);

        if (
            errorArrray.length > 0 &&
            (
                field == 'chevaux fiscaux' || field == 'horsePower' ||
                field == 'email' ||
                field == 'nom' || field == 'name' ||
                field == 'numéro de sécurité sociale' || field == 'socialSecurityNumber' ||
                field == 'prénom' || field == 'firstName' ||
                field == 'role' ||
                field == 'téléphone' || field == 'phone'
            )
        ) {
          this.isAccountTabError = true
        }

        if (
            errorArrray.length > 0 &&
            (
                field.startsWith('ETP') || field.startsWith('fte') ||
                field.startsWith('début') || field.startsWith('start') ||
                field.startsWith('fin') || field.startsWith('end') ||
                field.startsWith('fonction') || field.startsWith('function') ||
                field.startsWith('salaire brut') || field.startsWith('grossSalary') ||
                field.startsWith('type')

            )
        ) {
          this.isContractsTabError = true
        }

      }
    }
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>